import log from "../images/log.jpeg"
import "../css/online.css"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../../context/notes/noteContext';
import { NavLink } from "react-router-dom";

const Online = () => {
  const context = useContext(noteContext);
  let navigate = useNavigate();
  const { getNotes } = context;
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getNotes()
    }
    else {
      navigate("/login/");
    }

  }, [])


  return (
    <>
      <img src={log} class="img-fluid img-webinar" alt="online"></img>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="" style={{ color: "white" ,textDecoration:"none"}}><h5><b>*** Cold Market Means That You Don't Know Those People </b></h5></NavLink>
      </div>
      <button className='webinar' type='submit' style={{borderRadius:"3em"}}> <NavLink to="https://youtu.be/5MvQRoAe2Zs" style={{ color: "black" }}><h5> Presentation Link By Sonu Sir</h5></NavLink></button>
      <button className='webinar' type='submit' style={{borderRadius:"3em"}}> <NavLink to="https://youtu.be/e77xHzB2uK0?si=4iO4bIkR02FoJN8f" style={{ color: "black" }}><h5> 10 Min Presentation Link</h5></NavLink></button>

      <div class=" p-3 mb-5 bg-body-tertiary rounded center mt-5">
        <NavLink to="/Skills" style={{ color: "white" }}> <h5><b>** Learn Only these 5 Skills </b></h5></NavLink>
      </div>
      <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="/Master" style={{color:"white" }}><h5> <b>** Go Master on Your Work</b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="/Hosting" style={{color:"white" }}><h5> <b> Right Way to Host Meeting  </b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="/Hosting" style={{color:"white" }}><h5> <b>Objection Handling Questions</b></h5></NavLink>
        </div>

    </>
  )
}

export default Online