import React, { useContext, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom';
// import noteContext from '../context/notes/noteContext';
// import team from "./images/team.jpg"
import "./css/profile.css"
// import chandan from "./images/chandan.jpg"
import { NavLink } from 'react-router-dom'
// import hand from "./images/hand.jpg"
// import jc from "./images/jc.jpg"
import team2 from "./images/team2.jpg"
import Contact from './Contact';
import wts from "./images/wts.jpg"
import Services from './Services';
import c13k from "./images/c13k.jpg"
import c30k from "./images/c30k.jpg"
import v2p from "./images/v2p.jpg"
import k2k from "./images/k2k.jpg"
import s2s from "./images/s2s.jpg"
import c77k from "./images/c77k.jpg"
import chandanss from "./images/chandanss.jpeg"
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Profile = (props) => {

    // const context = useContext(noteContext);
    // let navigate = useNavigate();
    // const { getNotes } = context;
    // useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         //   alert = "logged in";
    //         navigate("/Home");

    //     }
    //     else {
    //         // getProfile()
    //     }

    // }, [])
    // const showAlert = props

    const notify = () => toast("Great !, Fill the Form, Chandan Rana Will Contact You Soon ...");


    return (
        <div>

            <div className='fix'>

                <a href="#apply" onClick={notify} style={{ color: "white" }}> Join Chandan Rana</a>

            </div>


            <div className='wts'>


                {/* <NavLink to="" style={{ color: "white" }}>  </NavLink> */}
                <NavLink to="https://wa.link/xnqghx" style={{ color: "white" }}> <img src={wts} className="" alt="..." /></NavLink>

            </div>


            <div className=" text-bg-primary mb-3" >
                <div className=""><h3 style={{ color: "white" }}>Team Chandan Rana |<br /><br /> <h4 style={{ fontFamily: "math" }}>Mission to Get Money & Time Freedom </h4> </h3> <hr style={{ backgroundColor: "white" }} />
                </div>
                <div className="card-body">
                    <p className="card-text" style={{ alignItems: "center", color: "white", justifyContent: "center", display: "flex" }}><h6><br /><br /><b>Every-one Knows Money is Most Important But Business Provide us Time Freedom.. & We 'r Working on It.. </b></h6></p>

                </div>

            </div>



            <div className=" p-3 mb-5 bg-body-tertiary rounded center" >

                <h3 className="fs-prospecting" ><h5><b style={{ lineHeight: "1.5em", color: "white", fontSize: "0.9em" }}>** Hum offer Karte hai , Ek Best Business jo Aap Apne Work ya Study Ke Saath Start Kar Sakte Hai<br />....................With Sonu Sharma Sir (Motivational Speaker)</b> <br /> <hr /> <b style={{ color: "#00fbff" }}> <br /> official ~ <br /> Team Sonu Sharma </b> </h5> <br />
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <a href='#apply'>
                            <button className="btn btn-primary me-md-2" onClick={notify} type="button">Contact officially Chandan Rana</button>
                        </a>

                    </div>
                </h3>


            </div>
            <div className="card-header"><h3>Team Sonu Sharma ~ Tss </h3></div>

            <figure className="figure mt-5 flx">
                <img src={chandanss} class="figure-img img-fluid rounded mt-3" alt="..." />
                <figcaption class="figure-caption flx2 mt-3" >Team Sonu Sharma is a network marketing organization and is associated with Sonu Sharma, the founder of Dynamic India Group. You can contact Team Sonu Sharma by calling 7324837251 on WhatsApp. You can also follow Team Sonu Sharma on Instagram at @official.motivation.tss
                    Sonu Sharma is also an inspirational speaker, corporate trainer, and life coach. He has a Master's Degree in Business Administration and has worked with the Press Trust of India, CBRE, and other organizations.</figcaption>
            </figure>

            <hr style={{ backgroundColor: "white" }} />

            <div className="card-header"><h3>Profile - Mr Chandan Rana </h3></div>

            <figure className="figure flx">
                <img src={team2} class="figure-img img-fluid rounded mt-5" alt="..." />
                <figcaption class="figure-caption flx2 mt-5" >Basically I Belong to Jharkhand From Hazaribag City And Started This Journey Along With My Study (BCA) , Other Students Wastes Their Time in This Golden Age (18-25) or They do Nothing But I Realize to Help People Make Money Along With Myself<br /><br /> The Question is Simple That If I Can Do , <br />Then You Can Also Definitely do , Just Believe in Yourself.. & Achieve it</figcaption>
            </figure>

            <hr style={{ backgroundColor: "white" }} />


            <div className='container-payout mt-5'>
                <h1 className='mt-3' style={{ color: "white" }}> Highest Payout Achiever's</h1>

                <div id="carouselExampleInterval mt-4" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner mt-4">
                        <div class="carousel-item active" data-bs-interval="10000">
                            <img src={c77k} class="d-block w-100 image" alt="..." />
                        </div>
                        <div class="carousel-item" data-bs-interval="2000">
                            <img src={c30k} class="d-block w-100 image" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={c13k} class="d-block w-100 image" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={k2k} class="d-block w-100 image" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={s2s} class="d-block w-100 image" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={v2p} class="d-block w-100 image" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

                <div className="text-bg-info mt-5 mb-3">
                    <div className="card-header"><h3 style={{ color: "#5eff00" }}> Earn With Team Chandan Rana </h3></div>
                    <div className="card-body">
                        <p className="card-text "><h6 className='bada' style={{ color: "white" }}> Most of the Above People Stay at Home Operate This Business From Their Home , You Can Also Start & Include in our Team , We 'r Selecting 20 People Just Hurry up ! </h6></p>
                    </div>

                </div>

            </div>




            <hr style={{ backgroundColor: "white" }} />



            <div className="text-bg-primary mb-3 center-hand" >
                {/* <img src={hand} className="img-fluid sticy-top img-hand" alt="..." /> */}
                <div className="fs mt-4"><h2 style={{ fontSize: "1.2em", color: "white" }}>Start <b> Business </b>With Chandan Rana</h2>
                    <NavLink to="https://wa.link/xnqghx " style={{ color: "white",backgroundColor:"blue" }}>Just Tap For Magic </NavLink>
                </div>


            </div>
            {/* <hr style={{ backgroundColor: "white" }} /> */}
            <div className="text-bg-info mt-4 mb-3">
                <div className="card-header"><h3>You 're Not Alone </h3></div>
                <div className="card-body">
                    <p className="card-text "><h6 className='bada' style={{ color: "white" }}>India is Facing an Unemployment crisis And With 90% Graduates Jobless , The Lack of Skills Like Leadership & Communication is a Big Factor...Millions of Job Exist Across Industries , But Job Seekers Aren't Ready to Go </h6> </p>
                </div>
            </div>

            <hr style={{ backgroundColor: "white" }} />


            <div className="text-bg-info mt-5 mb-3">
                <div className="card-header"><h3>Exclusive Digital Start-up </h3></div>
                <div className="card-body">
                    <p className="card-text "><h6 className='bada' style={{ color: "white" }}>Start Your own Digital Business While Maintaining Your Current Job or Doing Your Study And Generate Income From Home .. </h6> </p>
                </div>

            </div>






            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <a href='#apply'>
                    <button className="btn btn-primary me-md-2" onClick={notify} type="button">Contact officially Chandan Rana</button>
                </a>

            </div>
            <hr style={{ backgroundColor: "white" }} />

            <ToastContainer />

            <Services />
            <Contact />
        </div >
    )
}
export default Profile