import React from 'react'
import { NavLink } from 'react-router-dom'

const Sorry = () => {
    return (
        <div style={{display:"flex",flexDirection:"column",  alignItems:"center"}}>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center mt-5 mt-3" >
                <div className="fs-prospecting" style={{ color: "white", lineHeight: "" }}><div style={{ color: "skyblue" }}>Sorry , You 're Not The Team of Chandan Rana !📛🚫🚷</div><br /></div>
            </div>
            <br />  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <div className="fs-prospecting" style={{ color: "white", lineHeight: "" }}><div style={{ color: "skyblue" }}>If You Think , I 'm Wrong Then Please Contact Mr Chandan Rana By Calling 7324837251 ✅©️®️</div><br /></div>
            </div>
            <button className='webinar' type='submit' style={{ borderRadius:"5em"}}> <NavLink to="/Contact" style={{ color: "black" ,alignItems:"center", borderRadius:"9px", display:"flex", justifyContent:"center"}}> <b>Fill Your Contact Details ,Team 'll Contact You Soon </b></NavLink></button>

        </div>
    )
}

export default Sorry