import React from 'react'
// import zoomapp from "./audio/zoomapp"
const Video = () => {
  return (
    <div>
         <video src="" controls style={{
                    // boxShadow: "white 3px 5px 9px",
                    // height: "6vh",
                    // width: "72%",
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center" 
                }} />
    </div>
  )
} 
 
export default Video 