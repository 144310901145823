import React from "react"; 
import { useState } from "react"; 
import NoteContext from "./noteContext"; 
import { toast, } from "react-toastify";
// import { json } from "react-router-dom"; 

const NoteState = (props) => {
  const host = "https://inotebook-gqci.onrender.com"

  const notesInitial = []
  const [notes, setNotes] = useState(notesInitial)

  // Get All Notes
  const getNotes = async () => {
    // ToDo Api call
    //api call
    const response = await fetch(`${host}/api/Note/fetchallnotes`, {
      method: "GET",
      headers: { 
        'Content-Type': 'application/json', 
        "auth-token": localStorage.getItem('token')
      }
    });
    const json = await response.json()
    console.log(json)
    setNotes(json)
  }

  //Add a Note
  const addNote = async (title, description, count, tag) => {
    // ToDo Api call
    const response = await fetch(`${host}/api/Note/addnote`, {
      method: "POST",
      headers: { 
        'Content-Type': 'application/json', 
        "auth-token": localStorage.getItem('token') 
      }, 
      body: JSON.stringify({ title, description, count, tag })

    });
    const note = await response.json();
    setNotes(notes.concat(note)) 

    // console.log("Adding a new note")
   
  }
  //Delete a Note
  const deleteNote = async (id) => {
    //api call
    const response = await fetch(`${host}/api/Note/deletenote/${id}`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        "auth-token": localStorage.getItem('token')
      }
    });
   
    const json = response.json();
    console.log(json);

    console.log("Deleting the note with id " + id);
    const newNotes = notes.filter((note) => { return note._id !== id })
    setNotes(newNotes);
    return toast("Note deleted Successfully");
  }

  //Edit a Note
  const editNote = async (id, title, description, tag) => {
    //api call
    const response = await fetch(`${host}/api/Note/updatenote/${id}`, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        "auth-token": localStorage.getItem('token')
      },
      body: JSON.stringify({title, description, tag}) 
    });
    const json = await response.json();
    console.log(json)

     let newNotes = JSON.parse(JSON.stringify(notes))
    // logic to edit a isclient
    for (let index = 0; index < newNotes.length; index++) { 
      const element = newNotes[index]; 
      if (element._id === id) { 
        newNotes[index].title = title; 
        newNotes[index].description = description; 
        newNotes[index].tag = tag; 
        break;
      } 
    
    }
    console.log(id , notes)
    setNotes(newNotes)
  }


  return (
    <NoteContext.Provider value={{ notes, addNote, deleteNote, editNote, getNotes }}>
      {props.children}
    </NoteContext.Provider> 
  ) 
}

export default NoteState;
