import React from 'react'
import hosting1 from "./images/hosting1.jpg"
import hosting2 from "./images/hosting2.jpg"

const Invitaion = () => {
    return (
        <div>
           
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{flexDirection:"column"}} >
                <h2 style={{ lineHeight: "2em" , color:"white" }}> <b>Right Way to Host a Meeting</b><br />
                </h2>
                <img src={hosting1} class="img-fluid img-hosting1" alt="online"></img>
                <img src={hosting2} class="img-fluid img-hosting2" alt="online"></img>
            </div>
        </div>
    ) 
}

export default Invitaion