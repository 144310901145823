import React, { useContext } from 'react'
import noteContext from '../context/notes/noteContext';


const Noteitem = (props) => {

    const context = useContext(noteContext);
    const { deleteNote } = context;
    const { note, updateNote } = props;

    return (
        <div>
            <div className='col-md-3'>
                <div className="card my-3">
                    <div className="card-body" style={{color:"black"}}>
                        <div className='d-flex align-items-center'>
                            <h5 className="card-title" >{note.title}</h5>
                            <i className="fa-sharp-duotone fa-solid fa-trash mx-2" onClick={() => {deleteNote(note._id) }}></i>
                            <i className="fa-sharpp-duotone fa-ssolid fa-file-peen mx-2" onClick={() => { updateNote(note) }}><button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>Edit

                            </button> </i>
                        </div>
                        <p className="card-text" >{note.description}</p>
                        <h5 className="card-text">{note.desc}</h5>
 
                    </div> 
                </div>
            </div>
        </div>
    ) 
}

export default Noteitem