import React from 'react'
import { NavLink } from 'react-router-dom'

const Master = () => {
    return (
        <div style={{ color: "white" }}>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h2><b>3 Major Skills Sikhne Ke Baad Kya Kare </b></h2><br />
                    <b> 1. </b>Prospecting Kijiye Aur Usi Time Invitaion<br />
                    <b> 2. </b>Send a Two Min Video , Before Presentation<br />
                </h2>
            </div>

            <h4 className='center-home mt-5'>If She is Girl then Send</h4>

            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "5em", color: "aqua" }}></h3>
                <NavLink to="https://youtu.be/2xD5xFKREYw?si=sSSDWIwSX7NviZq4"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Mohini Jaiswaal</b></h5><br /><br /></NavLink>


                <NavLink to="https://youtu.be/xnFecZ95j8s?si=s7XUKE5HN2NugF7q"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Purnima Singh </b></h5><br /><br /></NavLink>

            </div>
            <h4 className='center-home mt-5'>If He is Boy then send </h4>

            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "5em", color: "aqua" }}></h3>
                <NavLink to="https://youtu.be/h6xBYeK6w6I?si=0O8L5DjAzLII2s_P"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Virendra Pratap</b></h5><br /><br /></NavLink>


                <NavLink to="https://shorturl.at/vQNhS"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Satyendra gurjar </b></h5><br /><br /></NavLink>

            </div>



            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>3. Presentation Attend Krwane Se Pehle Zoom App Ka Video Send<br />
                    <NavLink to="https://drive.google.com/file/d/1KhpRWRT1Ub_ykmiG4uHKICuXMLB2rDMB/view"
                        style={{
                            boxShadow: "white 3px 5px 9px",
                            height: "8vh",
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "none"
                        }}

                    > <h5 className='paperplan'><b style={{ color: "aqua" }}> Zoom App Video</b></h5><br /><br /></NavLink>

                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>4. After Webinar- Ask , Pure Webinar Me Sabse Badhiya Kya Laga Aapko<br />
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>5. Edification Kijiye , Jinse Baat Karwana Chahte Hai<br />
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>6. Proper Time Fix Kijiye Baat Karwane Ka & Send This ,
                    <NavLink to="/https://shorturl.at/ixfdf" style={{ color: "aqua" }}> <b>Video Link</b></NavLink>
                <br />
                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>7. Proper Baat Karwane Ke Baad , Follow Up Achhe Se Kijiye , Video Send Karke Bhi -
                    {/* <NavLink to="/Namelist" style={{ color: "black" }}> <b>Video Link</b></NavLink> */}
                <br />
                </h2>
            </div>

            <h4 className='center-home mt-5'>If She is Girl then Send</h4>

            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "5em", color: "aqua" }}></h3>
                <NavLink to="https://youtu.be/2xD5xFKREYw?si=sSSDWIwSX7NviZq4"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Mohini Jaiswaal</b></h5><br /><br /></NavLink>


                <NavLink to="https://youtu.be/xnFecZ95j8s?si=s7XUKE5HN2NugF7q"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Purnima Singh </b></h5><br /><br /></NavLink>

            </div>
            <h4 className='center-home mt-5'>If He is Boy then Send</h4>

            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "5em", color: "aqua" }}></h3>
                <NavLink to="https://youtu.be/h6xBYeK6w6I?si=0O8L5DjAzLII2s_P"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Virendra Pratap</b></h5><br /><br /></NavLink>


                <NavLink to="https://shorturl.at/vQNhS"
                    style={{
                        boxShadow: "white 3px 5px 9px",
                        height: "8vh",
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textDecoration: "none"
                    }}

                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Satyendra gurjar </b></h5><br /><br /></NavLink>

            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>8. Closing Ek Process Hai , Agar Payment Na Aaye To Upline Se Phir Ek Baar Time Lekar Baat Karwaiye <br />
                </h2>
            </div>

        </div>
    )
}

export default Master 