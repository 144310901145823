import React from 'react'
import online from "./images/online.png"
import { NavLink } from 'react-router-dom'
import "./css/online.css"

const Work = () => {
    return (
        <>
            <img src={online} class="img-fluid img-online" alt="online"></img>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center mt-5">
                <NavLink to="/Warm" style={{ color: "white", fontSize: "1em" }}><h3>Warm Market <NavLink to="/Warm"> </NavLink></h3></NavLink>
            </div> 
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="/Online" style={{ color: "ehite", fontSize: "1em" }}><h3>Cold Market <NavLink to="/Online"> </NavLink></h3></NavLink>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center"> 
                <NavLink to="/Noteshere" style={{ color: "ehite", fontSize: "1em" }}><h3>Notes Here <NavLink to="/Noteshere"> </NavLink></h3></NavLink>
            </div>
        </>
    )
}

export default Work