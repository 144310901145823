import React from 'react'
import { NavLink } from 'react-router-dom';

const Noteshere = () => {
  return (
    <div>

      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="https://skill.learnwithankit.in/" style={{ color: "aqua" }}><b>Ankit Saini Course</b></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="https://www.edywo.com/courses/enrolled/1925014" style={{ color: "aqua" }}><b>Video Editing Premeire Pro</b></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="https://apexdigistore.online/courses/facebook-ads-course-for-lead-generation-6/lesson/lesson-5-21/?fbclid=PAZXh0bgNhZW0BMAABpnAxd-IVdTjlBbMce-uqUYzmEBtEafgeTMMtcU0upm9NOOcJlHypGlpb8w_aem_7AIIX_FUDC8QFQWj4l2QWQ" style={{ color: "aqua" }}><b>Fb Ad-Setup Lead Generation</b></NavLink>
      </div>

    </div>
  )
}

export default Noteshere