import skill from "./images/skill.jpeg"
import "./css/online.css"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from './../context/notes/noteContext';
import { NavLink } from "react-router-dom";

const Online = () => {
  const context = useContext(noteContext);
  let navigate = useNavigate();
  const { getNotes } = context;
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getNotes()
    }
    else {
      navigate("/login/");
    }

  }, [])


  return (
    <>
      <img src={skill} class="img-fluid img-webinar" alt="online"></img>
      
      <div class=" p-3 mb-5 bg-body-tertiary rounded center mt-5">
        <NavLink to="" style={{ color: "white" , textDecoration:"none"}}> <h5><b>** Learn Only these 5 Skills </b></h5></NavLink>
      </div>
      <div style={{color:"white"}}>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center"> 
          <NavLink to="/Prospecting" style={{ color:"white" }} > <h5> <b> 1. Prospecting</b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="/Invitation" style={{color:"white" }} > <h5><b> 2. Invitation</b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="https://gamma.app/docs/Welcome-z8epu17tr002tac?mode=doc#card-thsga06pj5khep3" style={{color:"white" }} > <h5> <b> 3. Presentation</b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="/Edification" style={{color:"white" }} > <h5> <b> 4. Edification</b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="" style={{ color:"white"}}><h5> <b> 5. Follow Up</b></h5></NavLink>
        </div>
        <div class=" p-3 mb-5 bg-body-tertiary rounded center">
          <NavLink to="" style={{color:"white" }}> <h5><b> 6. Closing</b></h5></NavLink>
        </div>
       
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
        <NavLink to="" style={{ textDecoration:"none" }}><b><h3 style={{color:"skyblue"}}>**3 Major & Most Important Skill in online</h3>  </b></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="" style={{ color: "white" ,textDecoration:"none" }}> <h5><b>** Prospecting & Invitation </b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Prospecting" style={{ color: "white",textDecoration:"none" }}> <h5><b>Edification & </b></h5></NavLink>
      </div>
      <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
        <NavLink to="/Invitaion" style={{ color: "white",textDecoration:"none" }}><h5> <b>Follow Up</b></h5></NavLink>
      </div>

    </>
  )
}

export default Online