import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaLock, FaUser } from 'react-icons/fa'
import "../components/login/login2.css"
import { NavLink } from 'react-router-dom'
// import Contact from './Contact'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
// import Contact from './Contact'

const Login = (props) => {

    const notify = () => toast("Chandan Rana Welcomes You in our Team");

    const [credentials, setCredentials] = useState({ email: "", password: "" })
    let navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch("https://inotebook-gqci.onrender.com/api/Auth/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: credentials.email, password: credentials.password })
        });
        const json = await response.json()
        console.log(json)
        if (json.success) {
            //save the authtoken and redirect 
            // {loading && <Spinner />}  

            localStorage.setItem('token', json.authtoken);


            navigate("/Home");
            props.showAlert("Login Successfully Done ", "success")
            return toast('Login Successfully Done !');


        }
        else {
            return toast('Please Enter Correct Id & Password');
        }
    }

    const onchange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    return (
        // <form onSubmit={handleSubmit}> 
        //     <div className="mb-3"> 
        //         <label htmlFor="email" className="form-label"> Enter Your Email </label> 
        //         <input type="email" className="form-control" value={credentials.email} onChange={onchange} name='email' id="email" aria-describedby="emailHelp" />
        //         <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> 
        //     </div> 
        //     <div className="mb-3"> 
        //         <label htmlFor="password" className="form-label">Password</label> 
        //         <input type="password" className="form-control" value={credentials.password} onChange={onchange} name="password" id="password" />
        //     </div>

        //     <button type="submit" className="btn btn-primary" >Submit</button>
        // </form>


        <div className=''>
            <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "aqua" }}>Mr. Chandan Rana Welcome You ... </h3>

            <div className='container-nb' style={{ backgroundColor: "tranparent", border: "display-block", display: "flex", flexDirection:"column" }}>

                <div className='form-box2' style={{ color: "white" }}>

                    <form action='' method='' style={{ marginTop: "18em" }} onSubmit={handleSubmit}>
                        <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Login to Access</h2>
                        <div className='input-box2 mt-5'>
                            <input type='number' placeholder=" Enter Your Mobile Number" value={credentials.email} onChange={onchange} name='email' id="email" required />
                            {/* <FaUser className='icon2' />  */}
                        </div>
                        <div className='input-box2'>
                            <input type='password' placeholder=' Password' value={credentials.password} onChange={onchange} name="password" id="password" required />
                            <FaLock className='icon2' />
                        </div>

                        {/* <div className='remember-forgot2'>
                            <label><input type='checkbox' /> Remember Me</label><a href='#' style={{ color: "#89ffae" }}> Forgot Password</a>
                        </div>  */}
                        {/* <label> */}
                        <button className='btn0' type='submit' onClick={notify}>Login</button>
                        {/* </label> */}

                        <br />
                        <div className='register-link2'>
                            <p><NavLink to="/Sorry" style={{ color: "#80ff32" }}> Don't have an account? Click here!</NavLink></p>
                        </div>
                        {/* <div className='register-link2 mt-4'>
                            <p><NavLink to="/Contact" style={{ color: "#80ff32" }}> Click Here To Fill Your Contact Details</NavLink></p>
                        </div> */}


                    </form>
                </div>
                <button className='webinar' type='submit' style={{ borderRadius:"5em"}}> <NavLink to="/Contact" style={{ color: "black" ,alignItems:"center", display:"flex", justifyContent:"center"}}><h5> <b>Fill Your Contact Details </b></h5></NavLink></button>


            </div>
                            
            <ToastContainer />
        </div>

    )
}

export default Login