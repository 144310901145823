import React from 'react'
import p1modified from "../images/p1modified.jpg"
import p2modified from "../images/p2modified.jpg"
import p3modified from "../images/p3modified.jpg"
import invitation from "../audio/invitation.wav"
import { NavLink } from 'react-router-dom'

const Invitaion = () => {
    return (
        <div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ color: "white", lineHeight: "2em" }}><h1 style={{ color: "skyblue" }}><b>Baic Important Points of Invitaion</b></h1><br />
                    <b> 1. </b>Note:- Invitaion Must only in 2 Minutes <br />
                    <b> 2. </b>5 Baby Steps of Invitaion<br />..........Intro<br />............Need <br />..............Value<br />................No Exist Cost<br />..................Create Urgency ( Limited Time , Limited offer , Limited Peoples )<br />
                    <b> 3. </b>Mental Exercise For Invitation<br />...........1. Feel Confidence 2. Giving Atitude 3. Excitement<br />
                    <b>4. </b>Confidence se Baat Kijiye<br />


                </h2>
            </div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >
                <h2 style={{ lineHeight: "2em", color: "white" }}> <b>Written Page Invitaion</b><br />
                </h2>
                <img src={p1modified} class="img-fluid img-prospecting1" alt="online"></img>
                <img src={p2modified} class="img-fluid img-prospecting2" alt="online"></img>
                <img src={p3modified} class="img-fluid img-prospecting3" alt="online"></img>
            </div>

            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="/Edification" style={{ color: "white" }} > <h5> <b>Go For Edification</b></h5></NavLink>
            </div>

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-prospecting"><h3 style={{ color: "white" }}>Play Sound:-</h3>
                <audio src={invitation} controls style={{
                    boxShadow: "white 3px 5px 9px",
                    height: "6vh",
                    width: "72%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }} /> <br /><br />


            </div>
            <hr style={{ backgroundColor: "white" }} />

            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="https://drive.google.com/file/d/1KhpRWRT1Ub_ykmiG4uHKICuXMLB2rDMB/view?usp=drive_link" style={{ color: "white" }} > <h5> <b>Zoom App Video</b></h5></NavLink>
            </div>

            <hr style={{ backgroundColor: "white" }} />

            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ color: "white" }}>
                <h4><b> All The Very Best to You From My Side</b></h4>
            </div>
        </div>
    )
}

export default Invitaion