import React from 'react'
import p1 from "../images/p1.jpg"
import p2 from "../images/p2.jpg"
import "../css/work.css"
import { NavLink } from 'react-router-dom'

const Edification = () => {
    return (
        <div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em" , color:"white" }}><h1 style={{color:"skyblue"}}><b>Edification , Value Create Karna</b></h1><br />
                    <b> 1. </b>अब आपने प्रेजेंटेशन देख लिया इस बिजनेस के बारे में समझ लिया लेकिन बात यहीं खत्म नही होती है
                    <br />
                    <b> 2. </b>अब मै आपको एक ऐसे परसन से मिलाने जा रहा हूं जो डायरेक्ट सोनू शर्मा सर से कनेक्टेड है 

                    <br />
                    <b> 3. </b>एक बार उनको लग गया ना की आपके भी सपने है जो की आप पूरा करना चाहते है 
                    <br />
                    <b> 4. </b>अगर उनका भी सपोर्ट मिलने लग गया ना तो समझ लो आपकी तो निकल पड़ी 

                    <br />
                    <b>5. </b>क्योंकि मेरा मानना है इंटेलिजेंट बच्चो को हर कोई आगे बढ़ा दे लेकिन जो साधारण लोगों को भी आगे लेके चले जाए वो ऐसे परसन है 
                    <br />
                    <b>6. </b>लेकिन उनसे ऐसे बात नहीं कर सकते टाइम लेना होगा हमे  <br />
                    <b>7. </b>क्योंकि अकसर वे मीटिंग मे बिज़ी रहते है 

                    <br />
                    <b>8. </b>तो सर से बात करने के लिए हम किस टाइम पे बात करे
                    <br />
                    <b>9. </b>चलो फिर मैं एक बार सर से बात कर लेता हूं फिर आपको समय मिलते हीं मैसेज कर दूंगा ... Okk<br />
                  
                </h2>
            </div>

            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                <NavLink to="/Online" style={{ color: "white" }} > <h5> <b>Go to Home Skills</b></h5></NavLink>
            </div>
            {/* <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-prospecting" >
                <h2 style={{ lineHeight: "2em" }}> <b>Written Page Prospecting</b><br />
                </h2>
                <img src={p1} class="img-fluid img-prospecting" alt="online"></img> */}
                {/* <img src={p2} class="img-fluid img-prospecting" alt="online"></img> */}
            {/* </div> */}

            {/* <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-prospecting">
                <NavLink to="https://youtu.be/Gr5lt8zqvME?si=o52CHLkcbFhlewC_" style={{ color: "blue" }}> <h5><b>Live Prospecting:- Video Link 1 , </b></h5></NavLink> <br /><br />
                <NavLink to="https://youtu.be/TPSPCDQMP8g?si=jh6n42aDntj6sZPD" style={{ color: "blue" }}> <h5><b>Video Link 2</b></h5></NavLink>

            </div> */}
            <hr style={{backgroundColor:"white"}}/>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{color:"white"}}>
                <h4><b> All The Very Best to You From My Side</b></h4>
            </div>

        </div>
    )
}

export default Edification